<div class="add-user-dialog">
  <div class="dialog-body">
    <div class="dialog-header">
      <div class="px-12">
        <span class="dialog-header-text">Add User</span>
        <div class="text-white text-14">We’ll email this user with instructions on how to login to Cartwheel</div>
      </div>
      @if (!(loading$ | async)) {
        <cartwheel-icon-button matIconName="clear" class="dialog-close-btn" (onClick)="closeDialog()" />
      }
    </div>
    <div class="dialog-content px-15 tablet:px-75">
      <form class="grid grid-cols-2 gap-20 py-28" name="addUserForm" [formGroup]="submitAddUser">
        <cartwheel-input label="First Name" [inputFormControl]="submitAddUser.get('firstName') | formControlType" />
        <cartwheel-input label="Last Name" [inputFormControl]="submitAddUser.get('lastName') | formControlType" />
        <cartwheel-input
          class="col-span-2"
          label="Email"
          [inputFormControl]="submitAddUser.get('email') | formControlType"
        />
        <cartwheel-input
          label="Hourly Rate"
          type="number"
          [inputFormControl]="submitAddUser.get('hourlyRate') | formControlType"
        />
        <cartwheel-input
          label="O/T Hourly Rate"
          type="number"
          [inputFormControl]="submitAddUser.get('otHourlyRate') | formControlType"
        />
        @if (userPosition == UserPosition.Employee) {
          <cartwheel-select
						class="col-span-2"
            label="Permissions"
            [options]="[
              { label: 'Admin', value: userRole.Admin },
              { label: 'Manager', value: userRole.Manager },
              { label: 'User', value: userRole.User }
            ]"
            [selectFormControl]="submitAddUser.get('permissions') | formControlType"
          />
        }
        @if (userIsInvoicedSeparately$ | async) {
          <mat-checkbox
            color="primary"
            class="col-span-2 my-10"
            labelPosition="before"
            [formControl]="submitAddUser.get('companyRole.userCompanySettings.generateInvoicesSeparately') | formControlType">
              Generate this users' invoices separately?
          </mat-checkbox>
        }
        <div class="grid grid-cols-1 col-span-2 gap-x-[10%] gap-y-20">
          @for (customUserFieldForm of customUserFields.controls; track 'form-group' + $index) {
            <form [formGroup]="customUserFieldForm">
              @for (control of customUserFieldForm.controls | keyvalue; track 'form-control' + $index) {
                <cartwheel-input 
                  class="block"
                  type="text"
                  [label]="control.key"
                  [inputFormControl]="control.value | formControlType"
                />
              }
            </form>
          }
        </div>
        @if (error$ | async) {
          <span class="text-danger text-12">{{ error$ | async }}</span>
        }
        <div class="flex items-center col-span-2 gap-5">
          <cartwheel-button
            class="[&>button]:px-40"
            label="Save"
            color="secondary"
            (onClick)="save()"
            [status]="(submitAddUser.pristine || submitAddUser.invalid) && successStatus.Disabled"
          />
          @if (!(loading$ | async)) {
            <cartwheel-button label="Cancel" type="text-button" color="middleGray" (onClick)="closeDialog()" />
          }
        </div>
      </form>
    </div>
  </div>
</div>
