import { InvariantError } from "../../utilities/globals/index.js";
export var serializeFetchParameter = function (p, label) {
    var serialized;
    try {
        serialized = JSON.stringify(p);
    }
    catch (e) {
        var parseError = __DEV__ ? new InvariantError("Network request failed. ".concat(label, " is not serializable: ").concat(e.message)) : new InvariantError(21);
        parseError.parseError = e;
        throw parseError;
    }
    return serialized;
};
