<div class="timer-container">
  @if (timerIsRunning$ | async) {
    <div class="timer">
      <p class="font-bold text-18 text-darkerGray">{{ currentEntry.description }}</p>
      <p>Client: {{ (selectedClient$ | async)?.clientName }}</p>
    </div>
  }
  <div class="clock-wrapper">
    <p class="clock-label">
      {{ (timerIsRunning$ | async) ? (timerValue$ | async) : '00:00:00' }}
    </p>

    <cartwheel-icon-button
      [matIconName]="(timerIsRunning$ | async) ? 'stop' : 'play_arrow'"
      type="fab-button"
      [color]="(timerIsRunning$ | async) ? 'danger' : 'secondary'"
      [size]="isMobile ? 'md' : 'lg'"
      [status]="accountActive ? successStatus.Enabled : successStatus.Disabled"
      (onClick)="ToggleTimer()"
    />
  </div>
</div>