import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';


export const routes: Routes = [
  {
    path: 'Login',
    title: 'Login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'Unauthorized',
    title: 'Unauthorized',
    loadComponent: () => import('./login/unauthorized/unauthorized.component').then(m => m.UnauthorizedComponent)
  },
  {
    path: 'Home',
    title: 'Home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'Projects',
        title: 'Projects',
        loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: 'Timesheets',
        title: 'Timesheets',
        loadChildren: () => import('./report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'Clients',
        title: 'Clients',
        loadChildren: () => import('./clients/clients.module').then(m => m.ClientsPageModule)
      },
      {
        path: 'ftux',
        title: 'Ftux',
        loadComponent: () => import('./ftux-container/ftux-container.component').then(m => m.FtuxContainerComponent),
        pathMatch: 'full',
        children: [
          {
            path: 'Veem',
            title: 'Veem',
            loadComponent: () => import('./ftux-container/ftux-container.component').then(m => m.FtuxContainerComponent),
          },
          {
            path: 'team',
            title: 'Team',
            loadComponent: () => import('./ftux-container/enterprise-ftux/enterprise-ftux-container.component').then(m => m.EnterpriseFtuxContainerComponent)
          }
        ]
      },
      {
        path: 'ftux/team',
        title: 'Ftux/team',
        loadComponent: () => import('./ftux-container/enterprise-ftux/enterprise-ftux-container.component').then(m => m.EnterpriseFtuxContainerComponent)
      },
      {
        path: 'Settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
      },
      { 
        path: 'ChangePassword',
        title: 'Change Password',
        loadComponent: () => import('./settings/change-password/change-password.component').then(m => m.ChangePasswordsComponent)
      },
      { path: '',
        title: '',
        loadChildren: () => import('./main-dashboard/main-dashboard.module').then(m => m.MainDashboardModule)
      },
      {
        path: '**',
        title: 'Page Not Found',
        loadComponent: () => import('./shared/components/page-not-found/page-not-found').then(m => m.PageNotFoundComponent)
      },
    ]
  },
  // Only place `HomeComponent` should be used is above, fix this with proper routes to proper components
  {
    path: 'Team',
    title: 'Team',
    component: HomeComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'Users',
        title: 'Users',
        loadChildren: () => import('./team/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'Projects',
        title: 'Projects',
        loadChildren: () => import('./team/projects/project.module').then(m => m.TeamProjectModule)
      },
      {
        path: 'Invoices',
        title: 'Invoices',
        loadChildren: () => import('./team/invoices/invoices.module').then(m => m.InvoicesListPageModule),
      },
      {
        path: 'Clients',
        title: 'Clients',
        loadChildren: () => import('./team/clients/clients.module').then(m => m.ClientsModule),
      },
      {
        path: 'Timesheets',
        title: 'Timesheets',
        loadChildren: () => import('./team/report/enterprise-report.module').then(m => m.ReportModule),
      },
      {
        path: 'Reports',
        title: 'Reports',
        loadChildren: () => import('./team/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'Settings',
        title: 'Settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'ChangePassword',
        title: 'Change Password',
        loadComponent: () => import('./settings/change-password/change-password.component').then(m => m.ChangePasswordsComponent)
      },
      {
        path: '',
        title: '',
        loadChildren: () => import('./main-dashboard/main-dashboard.module').then(m => m.MainDashboardModule)
      },
      {
        path: '**',
        title: 'Page Not Found',
        loadComponent: () => import('./shared/components/page-not-found/page-not-found').then(m => m.PageNotFoundComponent)
      },
    ]
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./login/logged-in/logged-in.component').then(m => m.LoggedInComponent)
  },
  {
    path: '**',
    title: 'Home',
    component: HomeComponent,
    children: [
      {
        path: '',
        title: 'Page Not Found',
        loadComponent: () => import('./shared/components/page-not-found/page-not-found').then(m => m.PageNotFoundComponent)
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService]
})
export class CartwheelWebRoutingModule { }
