import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { Apollo, gql, Subscription } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class TimerStoppedSubscription extends Subscription {
  document;

  constructor(apollo: Apollo, id: UUID) {
    super(apollo);
    this.document = gql`
      subscription {
        onTimerStopped(timeEntryId: "${id}") {
          timeEntryId
          endTime
        }
      }
    `
  }
}
