import {
  Component,
  ApplicationRef, OnInit
} from '@angular/core';
import { ResponsiveService } from './services/responsive.service';
import { from, Observable, zip, concat } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, getDashboardRole, getUserRole, getIsAuthenticated } from './redux/reducers/index';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AppUpdateService } from './services/app-update.service';
import { withLatestFrom, filter, skipWhile, take, skip } from 'rxjs/operators';
import { UserCompanyRole } from '@cartwheel/web-components';
import { UserRole } from './shared/enums';
import { ChangeCurrentRoleAction, GoToDashboardAction } from './redux/actions/dashboard';
import { CURRENT_ROLE, REDIRECT_PATH } from './shared/consts';
import { ExternalInvoiceService } from './services/external-invoice.service';
import { Location } from '@angular/common';
import { NgForage } from 'ngforage';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  private currentRole$: Observable<UserCompanyRole>;

  constructor(
    private store: Store<State>,
    public router: Router,
    public appRef: ApplicationRef,
    private externalInvoiceService: ExternalInvoiceService,
    private responsiveService: ResponsiveService,
    private location: Location,
    private route: ActivatedRoute,
    private localStorage: NgForage,
    private appUpdateService: AppUpdateService
  ) { }

  async ngOnInit() {
    this.appUpdateService.checkForUpdates();
    
    GoogleAuth.initialize({
      clientId: environment.google.clientId,
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    })


    from(this.localStorage.getItem(CURRENT_ROLE))
      .pipe(take(1), filter((cr: any) => cr))
      .subscribe((role: string) => {
        this.store.dispatch(new ChangeCurrentRoleAction(JSON.parse(role)))
      });

    this.currentRole$ = this.store.select(getDashboardRole).pipe(skipWhile(role => !role));
    // Every time a role changes we need to get the settings for that role
    // Possibly we can dispatch all necessary actions (get timesheets, get clients etc.)
    // in order to consolidate team vs user components
    // https://gitlab.com/cartwheel/CartwheelWeb/-/issues/818
    const routerEvents$ = this.router.events.pipe(filter((e: NavigationEnd) => e instanceof NavigationEnd));
    const authenticated$ = this.store.select(getIsAuthenticated).pipe(skipWhile(auth => !auth));

    concat(
      zip(routerEvents$, this.currentRole$, authenticated$).pipe(take(1)),
      routerEvents$.pipe(skip(1), withLatestFrom(this.currentRole$, authenticated$))
    )
      .pipe(
        filter(([event, currentRole, authenticated]) => authenticated && !!currentRole)
      ).subscribe(([event, currentRole, authenticated]) => {
        if (event.url.startsWith('/Team') && currentRole.userRole === UserRole.User) {
          this.store.dispatch(new GoToDashboardAction());
        }

        if (!event.url.startsWith('/Team') && currentRole.userRole !== UserRole.User) {
          this.store.select(getUserRole)
            .pipe(take(1))
            .subscribe((userRole) => this.store.dispatch(new ChangeCurrentRoleAction(userRole)));
        }
      });


    const lastPath: string = await this.localStorage.getItem<string | null>(REDIRECT_PATH);
    let path: string = lastPath || this.location.path().split('?')[0];
    let queryParams = Object.fromEntries(new URLSearchParams(window.location.toString().split('?')[1] ?? ''));

    if (!path) {
      // The path will be empty string if user visit app by entering the path routing url, not hash routing.
      // In this case, we should get that path using bellow code and keep proccess until we choose one between hash routing vs path routing.
      // This should be remove if we choose path routing.
      path = (window.location.hash.length > 0)
        ? window.location.hash.substring(1, window.location.hash.length)
        : window.location.pathname;

      queryParams = Object.fromEntries(new URLSearchParams(window.location.toString().split('?')[1] ?? ''));
    }

    if ((queryParams['code'] || queryParams['state']) && !path.startsWith('/Login')) {
      this.externalInvoiceService.storeInvoiceData(queryParams, path.substring(1));
    }

    await this.localStorage.removeItem(REDIRECT_PATH);

    await this.router.navigate([path.replace(/\/*Veem/g, '')], { queryParamsHandling: 'merge', queryParams });

    this.route.queryParams.pipe(take(1)).subscribe(params => {
      this.location.replaceState(this.location.path().split('?')[0]);
    });
  }

  onResize(event) {
    this.responsiveService.checkWidth();
  }
}
