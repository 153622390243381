
import { debounceTime, switchMap, catchError, map, mergeMap, tap, delay, withLatestFrom } from 'rxjs/operators';
import {
  ActionTypes, OpenInvoiceDialogAction, OpenInvoiceDialogSuccessfulAction, OpenInvoiceDialogFailedAction,
  UpdateGlobalUserSettings, UpdateGlobalUserSettingsSuccess, UpdateGlobalUserSettingsFailed, ResetGlobalSettingsStatus,
  GetUserSettingsSuccessAction,
  GetUserSettingsFailureAction,
  ShowCloseAccountDialogAction,
  ShowCloseAccountDialogSuccessAction,
  ShowCloseAccountDialogFailureAction,
  HideCloseAccountDialogAction,
  SubmitCloseAccountAction,
  SubmitCloseAccountSuccessAction,
  SubmitCloseAccountFailureAction,
  ShowReactivateAccountDialogSuccessAction, ShowReactivateAccountDialogFailureAction,
  ShowReactivateAccountDialogAction,
  UploadLogoRequestAction,
  UploadLogoSuccessAction,
  UploadLogoFailureAction,
  UpdateTaxTypeAction,
  UpdateTaxTypeSuccessAction,
  UpdateTaxTypeFailureAction,
  UpdateTaxTypeCompleteAction,
  ShowUpdateTaxTypeDialogAction,
  ShowDeleteTaxTypesDialogAction,
  DeleteTaxTypesAction,
  DeleteTaxTypesSuccessAction,
  DeleteTaxTypesFailureAction,
  DeleteTaxTypesCompleteAction,
  GetSettingHistory,
  GetSettingHistorySuccess,
  GetSettingHistoryFailure,
  ResetSettingHistoryStatus
} from '../actions/settings';
import { ActionTypes as FtuxActionTypes, DisableProvidersAction } from '../actions/ftux';
import { AuthService } from '../../services/auth.service';
import { LogoutFailure, LogoutSuccess, GetUserRolesAction } from '../actions/loginuser';
import { of, Observable, of as observableOf } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { GlobalSettingsService } from '../../services/global-settings.service';
import { GetCurrentProviderSuccessfulAction } from '../actions/ftux';
import { AddAlertAction, RemoveAllAlertsAction } from '../actions/alerts';
import { InvoiceDataSourceActions } from '../actions';
import { ApiErrorResponse } from 'app/models/apierror';
import { SnackbarService } from 'app/services/snackbar.service';
import { getDashboardRole, State } from '../reducers';

@Injectable()
export class GlobalSettingsEffects {
  openInvoiceDialog$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.OPENINVOICEDIALOG),
      switchMap((action: OpenInvoiceDialogAction) => {
        return this.globalSettingsService.showInvoiceDialog(action.payload).pipe(
          map(res => new OpenInvoiceDialogSuccessfulAction(action.payload)),
          catchError(err => of(new OpenInvoiceDialogFailedAction(err))));
      })));

  openCloseAccountDialog$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SHOWCLOSEACCOUNTDIALOG),
      switchMap((action: ShowCloseAccountDialogAction) => {
        return this.globalSettingsService.showCloseAccountDialog().pipe(
          map(res => new ShowCloseAccountDialogSuccessAction()),
          catchError(err => of(new ShowCloseAccountDialogFailureAction(err))));
      })));

  HideCloseAccountDialogAction = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.HIDECLOSEACCOUNTDIALOG),
      switchMap((action: HideCloseAccountDialogAction) => {
        return this.globalSettingsService.hideCloseAccountDialog()
      })), { dispatch: false });

  SubmitCloseAccount$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SUBMITCLOSEACCOUNT),
      switchMap((action: SubmitCloseAccountAction) => {
        return this.globalSettingsService.submitCloseAccount(action.payload).pipe(
          map(res => new SubmitCloseAccountSuccessAction()),
          catchError(err => of(new SubmitCloseAccountFailureAction(err)))
        )
      })
    ));

  SubmitCloseAccountSuccesss$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SUBMITCLOSEACCOUNTSUCCESS),
      switchMap((action: SubmitCloseAccountSuccessAction) => {
        return this.globalSettingsService.userAccountClosed()
      })
    ), { dispatch: false });

  logoutUser$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SUBMITCLOSEACCOUNTSUCCESS),
      switchMap((action: SubmitCloseAccountSuccessAction) => {
        return this.authService.logout().pipe(
          map(res => new LogoutSuccess(action.payload)),
          catchError(err => observableOf(new LogoutFailure(err))));
      })
    ));

  showActivatePayment$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SHOWREACTIVATEACCOUNTDIALOG),
      switchMap((action: ShowReactivateAccountDialogAction) => {
        return this.globalSettingsService.showReactivateAccount().pipe(
          map(pageDisabled => new ShowReactivateAccountDialogSuccessAction(pageDisabled)),
          catchError(err => of(new ShowReactivateAccountDialogFailureAction(err)))
        )
      })
    ));

  updateGlobalSettings: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.UPDATEGLOBALSETTINGS),
      switchMap((action: UpdateGlobalUserSettings) => {
        return this.globalSettingsService.updateGlobalSettings(action.payload).pipe(
          mergeMap(res => [
            new UpdateGlobalUserSettingsSuccess(action.payload),
            new GetUserRolesAction() // To trigger alerts for the current user without needing to reload
          ]),
          catchError(err => of(new UpdateGlobalUserSettingsFailed(err))));
      })));

  resetGlobalSettingsStatus: Observable<Action> = createEffect(() => this.action$
      .pipe(
        ofType(
          ActionTypes.UPDATEGLOBALSETTINGSSUCCESS,
          ActionTypes.UPDATEGLOBALSETTINGSFAILED
        ),
        delay(1000),
        map(() => ResetGlobalSettingsStatus())
      ));

  verifyIfAccountActive$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(ActionTypes.GETUSERSETTINGSSUCCESS),
    map(() => new ShowReactivateAccountDialogAction())
  ));

  setProviderState$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.GETUSERSETTINGSSUCCESS),
      map((action: GetUserSettingsSuccessAction) => new GetCurrentProviderSuccessfulAction(action.payload.externalInvoicingSystems))
    ));

  setInvoiceDataSourceState$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.GETUSERSETTINGSSUCCESS),
      map((action: GetUserSettingsSuccessAction) => InvoiceDataSourceActions.getCurrentInvoiceDataSourceSuccessAction({invoicingSystems: action.payload.externalInvoiceDatasources}))
    ));

  LoadSettingFromServer: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.GETUSERSETTINGS),
      switchMap(() => {
        return this.globalSettingsService.GetUserSettings().pipe(
          map(res => new GetUserSettingsSuccessAction(res)),
          catchError(err =>
            of(new GetUserSettingsFailureAction(err))))
      })));

  ResetAlertsandInvoiceProviderandInvoiceDataSourceState: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.GETUSERSETTINGS),
      mergeMap(() => [
        new RemoveAllAlertsAction(),
        new DisableProvidersAction(),
        InvoiceDataSourceActions.disableInvoiceDatasourcesAction()
      ])
    ));

  checkInvoiceProviderAlerts: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(FtuxActionTypes.GETCURRENTPROVIDERSUCCESSFUL),
      switchMap(action => this.authService.checkForAlerts()
        .pipe(
          mergeMap(alerts => alerts.map(alert => new AddAlertAction(alert)))
        )
      )
    ));

  uploadRequestEffect$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.UPLOADLOGOREQUEST),
      switchMap((action: UploadLogoRequestAction) => {
        return this.globalSettingsService.uploadLogo(action.payload).pipe(
          map(res => new UploadLogoSuccessAction({logoUrl: res})),
          catchError((error: ApiErrorResponse) => {
            if (!error.accountInActive) {
              this.snackbarService.displayServerError(error);
            }
            return observableOf(new UploadLogoFailureAction(error));
          })
        )
      })
    ));

  showDeleteTaxTypesDialogEffect$ = createEffect(() => this.action$.pipe(
    ofType(ShowDeleteTaxTypesDialogAction),
    switchMap(action => {
      return this.globalSettingsService.showDeleteTaxTypesDialog(action.payload).pipe(
        map(() => action)
      )
    }),
  ));

  showUpdateTaxTypeDialogEffect$ = createEffect(() => this.action$.pipe(
    ofType(ShowUpdateTaxTypeDialogAction),
    switchMap(action => {
      return this.globalSettingsService.showUpdateTaxTypeDialog(action.payload).pipe(
        map(() => action)
      )
    }),
  ));

  updateTaxTypeEffect$ = createEffect(() => this.action$.pipe(
    ofType(UpdateTaxTypeAction),
    switchMap(action => {
      return this.globalSettingsService.updateTaxType(action.payload).pipe(
        map((res) => UpdateTaxTypeSuccessAction({payload: res})),
        catchError(error => of(UpdateTaxTypeFailureAction({payload: error}))
      ))
    })
  ));

  updateTaxTypeSuccessEffect$ = createEffect(() => this.action$.pipe(
    ofType(UpdateTaxTypeSuccessAction),
    debounceTime(1000),
    switchMap(() => this.globalSettingsService.closeUpdateTaxTypeDialog()),
    map(() => UpdateTaxTypeCompleteAction())
  ));

  updateTaxTypeFailureEffect$ = createEffect(() => this.action$.pipe(
    ofType(UpdateTaxTypeFailureAction),
    debounceTime(2500),
    map(() => UpdateTaxTypeCompleteAction())
  ));

  deleteTaxTypesEffect$ = createEffect(() => this.action$.pipe(
    ofType(DeleteTaxTypesAction),
    switchMap(action => {
      return this.globalSettingsService.deleteTaxTypes(action.payload).pipe(
        map(() => DeleteTaxTypesSuccessAction({payload: action.payload})),
        catchError(error => of(DeleteTaxTypesFailureAction({payload: error}))
      ))
    })
  ));

  deleteTaxTypesSuccessEffect$ = createEffect(() => this.action$.pipe(
    ofType(DeleteTaxTypesSuccessAction),
    debounceTime(1000),
    map(() => DeleteTaxTypesCompleteAction())
  ));

  deleteTaxTypesFailureEffect$ = createEffect(() => this.action$.pipe(
    ofType(DeleteTaxTypesFailureAction),
    tap(action => {
      if (!action.payload.accountInActive) {
        this.snackbarService.displayServerError(action.payload)
      }
    }),
    debounceTime(2500),
    map(() => DeleteTaxTypesCompleteAction())
  ));

  getSettingHistory$ = createEffect(() => this.action$.pipe(
    ofType(GetSettingHistory),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([action, role]) => {
      return this.globalSettingsService.getSettingHistory(action.requestBody, role.companyId as string).pipe(
        map(result => GetSettingHistorySuccess({history: result})),
        catchError(error => of(GetSettingHistoryFailure({error: error})))
      )
    })
  ));

  resetSettingHistoryStatus$ = createEffect(() => this.action$.pipe(
    ofType(GetSettingHistorySuccess, GetSettingHistoryFailure),
    delay(1500),
    map(() => ResetSettingHistoryStatus())
  ));


  constructor(
    private action$: Actions,
    private globalSettingsService: GlobalSettingsService,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private store: Store<State>
  ) { }
}
