import dayjs from 'dayjs';
import { switchMap, take, takeWhile, map, skipWhile } from 'rxjs/operators';
import { Client } from '../models/Client';
import { TimerService } from '../services/timer.service';
import { EndTimerAction, OpenTimerDialogAction, CheckIfTimerRunningAction } from '../redux/actions/timer';
import { TimeEntry } from '../models/timeentry';
import { Observable, Subscription, of, EMPTY } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  getGSettingsAccountActive,
  getLoadedClients,
  getProjectsList,
  getRunningTimeEntry,
  getTimerLoading,
  getTimerRunningState,
  getTimerSelectedClient,
  getUserRole,
  State
} from '../redux/reducers/index';
import { Store } from '@ngrx/store';
import { Component, DestroyRef, OnInit } from '@angular/core';
import { ResponsiveService } from '../services/responsive.service';
import { LoadProjectsFromServerAction, SelectProjectAction } from '../redux/actions/project';
import { Title } from '@angular/platform-browser';
import { IntegrationService } from 'app/services/integration.service';
import { EventCategory } from 'app/shared/enums';
import { TimerStoppedFromServerAction } from 'app/redux/actions/timer';
import { TimerStoppedSubscription } from 'app/subscriptions/TimerStopped';
import { Apollo, SubscriptionResult } from 'apollo-angular';
import { TimerStartedEventResponse, TimerStartedSubscription } from 'app/subscriptions/TimerStart';
import { ClientService } from 'app/services/client.service';
import { ProjectService } from 'app/services/project.service';
import { SuccessStatus } from '@cartwheel/web-components';

@Component({
  selector: 'app-timeclock',
  templateUrl: './timeclock.component.html',
  styleUrls: ['./timeclock.component.scss']
})
export class TimeclockComponent implements OnInit {
  public loading$: Observable<boolean>;
  public timerIsRunning$: Observable<boolean>;
  public timeEntry$: Observable<TimeEntry>;
  public selectedClient$: Observable<Client>;
  public currentEntry: TimeEntry;
  public timerRunning: boolean;
  public timerValue$: Observable<string>;
  public timerValue: string;
  public isMobile: boolean;
  public accountActive: boolean;
  public successStatus = SuccessStatus;

  private timerSubscription = new Subscription();

  constructor(
    private store: Store<State>,
    private title: Title,
    private integService: IntegrationService,
    private timerService: TimerService,
    private responsiveService: ResponsiveService,
    private clientService: ClientService,
    private projectService: ProjectService,
    private apollo: Apollo,
    private destroyRef: DestroyRef
  ) { }

  ngOnInit() {
    this.store.dispatch(new CheckIfTimerRunningAction());
    this.loading$ = this.store.select(getTimerLoading);
    this.timeEntry$ = this.store.select(getRunningTimeEntry);

    this.responsiveService
      .getMobileStatus().pipe(
        takeUntilDestroyed(this.destroyRef))
      .subscribe(mobile => (this.isMobile = mobile));

    this.timeEntry$.pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe((entry: TimeEntry) => {
        this.currentEntry = entry;
      });
    
    this.store.select(getGSettingsAccountActive)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(s => this.accountActive = s);
    
    this.selectedClient$ = this.store.select(getTimerSelectedClient);
    this.timerIsRunning$ = this.store.select(getTimerRunningState);

    this.timerIsRunning$.pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe(isRunning => {
        this.timerRunning = isRunning;
        if (isRunning) {
          this.gqlSubscribeToTimerStoppage(this.currentEntry);
          this.timerValue$ = this.timerService.elapsedTime(this.currentEntry.startTime);
          this.timerSubscription = this.timerValue$
            .pipe(takeUntilDestroyed(this.destroyRef),takeWhile(e => this.timerRunning ))
            .subscribe(data => {
              if (data) {
                this.title.setTitle(`Cartwheel - ${data}`);
              }
            });
        } else {
          this.gqlSubscribeToTimerStarted();
          this.timerSubscription.unsubscribe();
          this.title.setTitle(`Cartwheel`);
        }
      });
  }

  gqlSubscribeToTimerStarted() {
    this.store.select(getUserRole).pipe(
      takeUntilDestroyed(this.destroyRef),
      skipWhile(r => !r),
      switchMap(role => new TimerStartedSubscription(this.apollo, role.userId).subscribe()),
      take(1),
      switchMap(sr => {
        return this.store.select(getLoadedClients).pipe(
          switchMap(clients => {
            const clientId = sr.data.onTimerStarted.clientId;
            const client = clients.find(c => c.clientID === clientId);
            return (!client) ? this.clientService.GetAllClientsFromServer().pipe(
              map(cs => cs.find(c => c.clientID === clientId))
            ) : of(client);
          }),
          map(client => [sr, client] as [SubscriptionResult<TimerStartedEventResponse>, Client])
        )
      }),
    ).subscribe(([sr, client]) => {
      this.store.select(getProjectsList).pipe(
        take(1),
        switchMap(projects => {
          const project = projects.find(p => p.projectId === sr.data.onTimerStarted.projectId);
          if (!project) {
            return this.projectService.GetAllProjectsFromServer();
          }
          return EMPTY;
        })
      ).subscribe(() => {
        this.store.dispatch(new CheckIfTimerRunningAction());
      })
    })
  }

  gqlSubscribeToTimerStoppage(entry: TimeEntry) {
    (new TimerStoppedSubscription(this.apollo, entry.timeEntryId))
    .subscribe()
    .pipe(take(1))
    .subscribe(sr => {
      this.store.dispatch(new TimerStoppedFromServerAction(new TimeEntry({
        ...entry,
        endTime: new Date(sr.data.onTimerStopped.endTime)
      })));
    })
  }

  public ToggleTimer() {
    if (this.accountActive) {
      // this is purely for testing
      const currentTime = dayjs().toDate();
      const entry = new TimeEntry(this.currentEntry);
      this.store.dispatch(new SelectProjectAction(undefined));
      if (!this.timerRunning) {
        entry.startTime = currentTime;
        entry.clientId = null;
        this.store.dispatch(new OpenTimerDialogAction(entry));
      } else {
        entry.endTime = currentTime;
        this.integService.trackEvent('Timer', {
          category: EventCategory[EventCategory.Timer],
          message: 'Stopped timer'
        });
        this.store.dispatch(new EndTimerAction(entry));
        this.store.dispatch(new LoadProjectsFromServerAction(null));
        this.store.dispatch(new SelectProjectAction(undefined));
      }
    }
  }
}
