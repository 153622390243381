import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { Apollo, gql, Subscription } from 'apollo-angular';
import { TimeEntry } from 'app/models/timeentry';

@Injectable({
  providedIn: 'root',
})
export class TimerStartedSubscription extends Subscription<TimerStartedEventResponse> {
  document;

  constructor(apollo: Apollo, userId: UUID) {
    super(apollo);
    this.document = gql`
      subscription {
        onTimerStarted(userId: "${userId}") {
          timeEntryId
          startTime
          clientId
          projectId
        }
      }
    `
  }
}

export interface TimerStartedEventResponse {
  onTimerStarted: Pick<TimeEntry, 'timeEntryId' | 'startTime' | 'clientId' | 'projectId'>
}
